'use client';

export default function ErrorLayout({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <html className={className}>
      <head></head>
      <body>
        <style
          id="ensite-global-style"
          dangerouslySetInnerHTML={{
            __html: `
              body {
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
              }

              html, body, body [data-nextjs-scroll-focus-boundary] {
                height: 100%;
              }`,
          }}
        />
        {children}
      </body>
    </html>
  );
}
